.tooltip-action-button-div {
  text-align: center;
}

.tooltip-action-button-div > p {
  font-size: 10;
  margin: 0;
}

.button-delete {
  color:#ef5350 !important;
}