.appbar {
  background-color: snow;
  z-index: 1201 !important;
}

.appbar__toolbar {
  display: flex;
  justify-content: space-between;
}

.appbar-logo-grid {
  display: grid;
  grid-template-columns: min-content min-content;
  align-items: center;
}

$nav-drawer-width: 240px;

.nav-drawer {
  width: $nav-drawer-width;
  flex-shrink: 0;
}

.nav-drawer-paper {
  width: $nav-drawer-width;
  padding-top: 60px;
}