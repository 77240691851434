.user-permission-radio-grid {
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  grid-gap: 6px;
}

.user-create-form {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 12px;
}

.user-permission-row {
  padding-top: 12px;
  grid-column: 1 / -1;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.invaid-email-field:focus {
  border: 1px solid red;
}

.group-dialog-grid {
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;
}

.group-dialog-name {
  grid-column: 1 / -1;
  width: 100%;
  display: inline-flex;
}



// .group-dialog-grid-two {
//   grid-column: span 2;
// }