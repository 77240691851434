.camera-table-controls-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;
  padding-bottom: 12px;
}

.camera-table-spinner {
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  align-items: center;
  min-height: 10vh;
}